import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
import { Query } from '@/entities/public/Resource/interfaces'
import { FileProcess } from '@/entities/files'
import { buildBasicUrlAttribute } from '@/utils/appraisal/general'
import dayjs from 'dayjs'
import { parseToNumber } from '@/utils/general'
import { Alias, Resource } from '@/entities/public'
import { Brand } from '@/entities/vehicle'
import { Stock } from '@/entities/sales'
import { NotificationContent } from '@/store/notification/state'
import { sendError } from '@/utils/notificationError'

@Component({
  methods: {
    ...mapActions('resources/form', [
      'fetchData', 'pushData', 'deleteFile',
      'fileProcessing', 'processCavAndTickets', 'removeData', 'findAttributes', 'findStockId', 'pdfToImage',
    ]),
    ...mapActions('notification', ['loadNotification', 'sendNotificationPersonalized', 'sendNotificationBroadcast', 'sendNotificationRol', 'removeNotification']),
    ...mapActions('resources', ['setActive']),
  },
  computed: {
    ...mapGetters('resources', ['getForm', 'all']),
  },
})
export class FilesProcess extends Vue {
  sendNotificationPersonalized!: (payload: { userId: number, message: any }) => Promise<void>
  sendNotificationBroadcast!: (payload: { message: any }) => Promise<void>
  sendNotificationRol!: (payload: { rol: string, message: any }) => Promise<void>
  removeNotification!: (payload: { messageId: string, userId: number | string }) => Promise<void>
  all!: Array<Resource>;
  setActive!: (resource?: Resource) => void;
  findStockId!: (id: string) => Promise<Record<string, any>>;
  deleteFile!: (
    payload: Array<{ id: number, idFileProcess: number }>
  ) => Promise<void>;

  getForm!: (model: string, slug?: string) => Resource
  findAttributes!: (ids: number[]) => Promise<any>
  removeData!: (payload: { model: string, fields?: any }) => Promise<any>;
  fileProcessing!: (payload: { idDeal: number, idFileProcess: number }) => Promise<void>;
  processCavAndTickets!: (idNegotiation: number) => Promise<void>;
  pdfToImage!: (url) => Promise<string>
  loadNotification!: (obj: NotificationContent) => Promise<void>;

  pushData!: (payload: {
    model: string
    fields?: insertInput | updateInput
  }) => Promise<any>;

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  async handleFileType (fileType, field, idProcess, id, fileParameter = null) {
    const fileFiltered = fileType.filter(_ => _)

    const isArrayFiles = fileFiltered.some(val => Boolean(val?.src))
    if (fileFiltered?.length) {
      const parameter = fileParameter || await this.fetchData({
        query: { name: 'find', model: 'FileParameter' },
        filter: {
          _and: [
            { id_process: { _eq: idProcess } },
            { name: { _eq: field.properties.name } },
          ],
        },
      })
      const files = isArrayFiles
        ? fileFiltered.map(fi => fi.id)
        : fileFiltered.map(fi => fi.file.id)
      const allow = await this.allowFile(files, id, parameter[0].id)
      if (!allow?.length) return

      const resp = await Promise.all(
        allow.map(item =>
          this.pushData({
            model: 'FileProcess',
            fields: {
              id_file: item,
              id_file_parameter: parameter[0].id,
              id_process_record: id,
            },
          })
        )
      )

      if (!field?.properties?.multiple) {
        await this.removeOldFile((resp as FileProcess[]).map(_ => _.id), parameter[0].id, id)
      }

      return resp
    }
  }

  async handleFileProcessing (file, idDeal) {
    if (file?.length) {
      const fileProcess = file[0] as FileProcess
      if (fileProcess?.id) {
        await this.fileProcessing({ idDeal, idFileProcess: fileProcess?.id })
      }
    }
  }

  async removeOldFile (newFileId, idParameter, id) {
    const findFiles = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id: { _nin: newFileId } },
          { id_process_record: { _eq: id } },
          { id_file_parameter: { _eq: idParameter } },
        ],
      },
    })

    if (findFiles?.length) {
      const files = findFiles.map(_ => {
        return {
          id: _.file.id,
          idFileProcess: _.id,
        }
      })

      await this.deleteFile(files)
    }
  }

  isArrayFiles (files) {
    return files?.some(val => Boolean(val?.src))
  }

  async allowFile (files, id, parameterId) {
    if (!files?.length) return []

    const process = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [{ id_file: { _in: files } }, { id_process_record: { _eq: id } }, { id_file_parameter: { _eq: parameterId } }],
      },
      force: true,
    })

    return files.filter(
      file => !process.some(fileProcess => fileProcess.file.id === file)
    )
  }

  async removeOrphanFiles (files) {
    const isFileProcess = files?.filter(
      file => !(file instanceof FileProcess)
    )

    if (isFileProcess?.length) {
      const data = isFileProcess.filter(file => !file?.idFileProcess)

      await this.deleteFile(data)
    }
  }

  async removeFile (data) {
    if (data?.id) {
      await this.deleteFile([{ id: data.file.id, idFileProcess: data.id }])
    }
  }

  async deleteFiles (files) {
    if (!files?.length) return
    await this.removeOrphanFiles(files)

    for (const file of files) {
      if (file instanceof FileProcess) {
        await this.removeFile(file)
      }
    }
  }

  openLink (link) {
    if (!link?.length) return
    if (!link.startsWith('https://') && !link.startsWith('http://')) {
      link = 'https://' + link
    }

    window.open(link, '_blank')
  }

  async openPortal (link, stock, chileautos = true) {
    if (!stock) return

    if (link?.length && chileautos) {
      this.openLink(link)
    }
    const vehicle = stock?.auto

    const attributesFounds = await this.findAlternativesAtt(vehicle)

    const attributes = attributesFounds?.filter(_ => ['Transmisión', 'Tracción', 'Combustible'].includes(_.component.name))

    if (chileautos) {
      await this.buildLinkChileAutos(stock)
    } else {
      await this.buildLinkMercadoLibre(link, vehicle, attributes)
    }
  }

  async buildLinkChileAutos (vehicle) {
    let stockFull

    if (vehicle instanceof Stock) {
      stockFull = vehicle
    }

    if (stockFull) {
      const { viewDetails: { appraisalLink } } = stockFull

      if (appraisalLink?.length) {
        this.openLink(appraisalLink)
        return
      }
    }

    const { version } = stockFull?.auto || vehicle

    const mileagePrices = await this.fetchData({
      query: { name: 'find', model: 'MileagePrice' },
      filter: { id_version_year: { _eq: version?.id } },
    })

    const mileageChileautos = mileagePrices?.find((item: any) =>
      item.mileageFrom === 0 && item.mileageTo === 100 && item.person && item.dateTo === null)
    if (mileageChileautos?.link) {
      this.openLink(mileageChileautos.link)
      return
    }

    const aliasBrand = await this.findAlias(version?.version?.model?.brand, 'brand') as Alias
    const aliasModel = await this.findAlias(version?.version?.model) as Alias

    const attributesFounds = await this.findAlternativesAtt(stockFull?.auto || vehicle)

    const attributes = attributesFounds?.filter(_ => ['Transmisión', 'Tracción', 'Combustible'].includes(_.component.name))

    const link = version?.buildLinkChileAutosAttribute(attributes, aliasBrand[0]?.name, aliasModel[0]?.name).replace(' ', '-')

    this.openLink(link)
  }

  async findAlias (brand: Brand, tableName = 'model'): Promise<Alias> {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: tableName } },
    })

    return this.fetchData({
      query: { name: 'find', model: 'Alias', order: { id: 'desc' } },
      filter: { _and: [{ id_process: { _eq: process[0].id } }, { id_process_record: { _eq: brand.id } }] },
    })
  }

  async buildLinkMercadoLibre (link, auto, attributes) {
    if (!auto) return

    const newLink = buildBasicUrlAttribute(link, auto, attributes)
    this.openLink(newLink)
  }

  async calculateTransfer (id) {
    if (!id) return
    const processAuto = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'auto' } },
    })
    const { municipalityTax, fixedTransferCosts } = processAuto[0].config

    const stock = await this.fetchData({
      query: { name: 'findLite', model: 'Stock' },
      filter: { id: { _eq: id } },
    })

    const stockDetail = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_stock: { _eq: id } },
    })

    const { expectedPublicationAmount } = stockDetail[0]
    const { price } = stock[0]
    const { auto } = stockDetail[0]

    let automotiveRegistrationCost = 0
    const year = Number(dayjs().format('YYYY'))

    if (auto?.generation?.id) {
      const filter = { _and: [{ id_generation: { _eq: auto.generation.id } }, { year: { _eq: year } }] }
      const registration = await this.fetchData({
        query: { name: 'find', model: 'AutomotiveRegistration' },
        filter,
      })
      automotiveRegistrationCost = registration?.[0]?.appraisal
    }

    let baseValue

    if (price?.amount && automotiveRegistrationCost) {
      baseValue = Math.max(price.amount, automotiveRegistrationCost)
    } else if (price?.amount) {
      baseValue = price.amount
    } else if (automotiveRegistrationCost && expectedPublicationAmount) {
      baseValue = Math.max(automotiveRegistrationCost, expectedPublicationAmount)
    } else if (expectedPublicationAmount) {
      baseValue = expectedPublicationAmount
    } else {
      return 0
    }

    return Math.round(parseToNumber((baseValue * municipalityTax) + fixedTransferCosts))
  }

  async findAlternativesAtt (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1
      ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    let alternativeAttributes
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1
        ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        alternativeAttributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    return alternativeAttributes || generation?.attributes || []
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  async buildFileProcessObj (fileObj) {
    return {
      id: fileObj.file.id,
      src: fileObj.file.uri,
      type: fileObj.file.type,
      name: fileObj.file.name,
      size: fileObj.file.checksum,
      idFileProcess: fileObj.id,
      isFileExpired: fileObj.file.isFileExpired,
      sourceLink: fileObj.file.sourceLink,
      error: fileObj.file.error,
      smallUrl: fileObj?.file.smallUrl,
      largeUrl: fileObj?.file?.largeUrl,
    }
  }

  async setFieldError () {
    await this.loadNotification({
      // @ts-ignore catch must be any or unknown interface
      message: `Por favor, complete todos los campos obligatorios para continuar con el proceso.`,
      notificationType: 'error',
    })
  }

  async redirectToPage (path, query, sortBy = null) {
    const { all } = this
    const resource = all.find(resource => resource.path === path)

    this.setActive(resource)

    const querySearch = Object.assign({}, this.$route.query, { search: JSON.stringify(query), sortBy })
    window.open(this.$router.resolve({ path, query: querySearch }).href, '_blank')
  }

  async copyToClipboard (key) {
    await this.loadNotification({
      // @ts-ignore
      message: `${key} copiado al portapapeles`,
      notificationType: 'success',
    })
    await navigator.clipboard.writeText(key)
  }

  async findPersonSearch (query) {
    return this.fetchData({
      query: { name: 'find', model: 'Person', order: { id: 'desc' } },
      filter: query,
      force: true,
      limit: 100,
    })
  }

  replaceSpace (input: string): string | null {
    if (!input) return null
    // Verificar si el string contiene al menos una letra
    if (/[a-zA-Z]/.test(input)) {
      // Reemplazar los espacios con '%'
      return input.replace(/\s/g, '%')
    }
    // Retornar null si no contiene letras
    return input
  }

  async getUserId (id, person = false) {
    const filter = person ? {
      id_person: {
        _eq: id,
      },
    } : {
      person: {
        employee: {
          id: {
            _eq: id,
          },
        },
      },
    }

    const user = (await this.fetchData({
      query: { name: 'findLite', model: 'User' },
      filter,
    }))[0]

    return user?.id
  }

  async sendNotification (message, userId, type = 'personalized', rol = null) {
    let response
    let attempts = 0
    const maxAttempts = 10

    while (attempts < maxAttempts) {
      try {
        if (type === 'personalized') {
          response = await this.sendNotificationPersonalized({ userId, message })
        } else if (type === 'broadcast') {
          response = await this.sendNotificationBroadcast({ message })
        } else if (type === 'rol') {
          response = await this.sendNotificationRol({ rol, message })
        }
        if (response?.status === 'success') return
      } catch (e) {
        response = e
      }

      if (attempts === maxAttempts - 1) {
        const fullUrl = window.location.origin + this.$router.currentRoute.fullPath
        const error = {
          app_name: `GENIO`,
          view: `${fullUrl}`,
          record_id: `Datos del mensaje ${JSON.stringify({ message, userId, type, rol })}`,
          context: `Rabbit: ${JSON.stringify(response)}`,
        }
        sendError(error)
      }

      attempts++
      await new Promise(resolve => setTimeout(resolve, 1000)) // Esperar 1 segundo antes de reintentar
    }
  }

  async deleteNotification (notification, userId) {
    let response
    let attempts = 0
    const maxAttempts = 10

    const payload = {
      messageId: notification.id,
      userId,
    }
    while (attempts < maxAttempts) {
      response = await this.removeNotification(payload)
      if (response?.data?.status === 'success') return
      await new Promise(resolve => setTimeout(resolve, 1000)) // Esperar un segundo antes de reintentar
      attempts++
    }

    if (attempts === maxAttempts) {
      const fullUrl = window.location.origin + this.$router.currentRoute.fullPath
      const error = {
        app_name: `GENIO`,
        view: `${fullUrl}`,
        record_id: `Datos del mensaje ${JSON.stringify({ notification, userId })}`,
        context: `Rabbit: ${JSON.stringify(response)}`,
      }
      sendError(error)
    }
  }
}
